function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _newArrowCheck(innerThis, boundThis) { if (innerThis !== boundThis) { throw new TypeError("Cannot instantiate an arrow function"); } }

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.index-of.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.error.to-string.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.test.js";
$(document).ready(function () {
  var titleText = '';
  var currentTopicIndex = -1;
  var docProgressiveReveal = false;
  var docAllowSkip = false;
  var topics = [];
  var scrollLastSectionToView = false;
  var scrollLastSectionPosition = 0;

  var setCurrentTopicNotifiers = function () {
    var notifiers = [];
    return {
      add: function add(id, callback) {
        notifiers.push({
          id: id,
          callback: callback
        });
      },
      remove: function remove(id) {
        notifiers = notifiers.filter(function (x) {
          return id !== x.id;
        });
      },
      invoke: function invoke() {
        for (var i = 0; i < notifiers.length; i++) {
          notifiers[i].callback();
        }
      }
    };
  }();

  function setCurrentTopic(topicIndex, notify) {
    if (typeof notify === 'undefined') {
      notify = true;
    }

    if (topics.length === 0) return;
    topicIndex = topicIndex * 1;
    if (topicIndex === currentTopicIndex) return;

    if (currentTopicIndex !== -1) {
      var el = $(topics[currentTopicIndex].jqElement);
      el.trigger('hide');
      el.removeClass('current');
      el.trigger('hidden');
      $(topics[currentTopicIndex].jqListElement).removeClass('current');
    }

    var currentEl = $(topics[topicIndex].jqElement);
    currentEl.trigger('show');
    currentEl.addClass('current');
    currentEl.trigger('shown');
    $(topics[topicIndex].jqListElement).addClass('current');
    currentTopicIndex = topicIndex;

    if (notify) {
      setCurrentTopicNotifiers.invoke();
    }

    setTimeout(function () {
      $(document).scrollTop(0);
    }, 0);
  }

  function updateLocation(topicIndex) {
    var baseUrl = window.location.href.replace(window.location.hash, '');
    window.location = "".concat(baseUrl, "#").concat(topics[topicIndex].id);
  }

  function handleTopicClick(event) {
    hideFloatingTopics();
    updateLocation(this.getAttribute('index'));
  }

  function showFloatingTopics() {
    $('.topicsList').removeClass('hideFloating');
  }

  function hideFloatingTopics() {
    $('.topicsList').addClass('hideFloating');
  }

  function updateVisibilityOfTopicElements(topicIndex) {
    resetSectionVisibilityList();
    var topic = topics[topicIndex];
    if (!topic.progressiveReveal) return;
    var showSection = true;
    var lastVisibleSection = null;

    for (var i = 0; i < topic.sections.length; i++) {
      var section = topic.sections[i];
      var sectionEl = $(section.jqElement);

      if (showSection) {
        sectionEl.trigger('show');
        sectionEl.removeClass('hide');
        sectionEl.trigger('shown');

        if (section.skipped) {
          sectionEl.removeClass('showSkip');
        } else {
          sectionEl.addClass('showSkip');
          lastVisibleSection = sectionEl;
        }
      } else {
        sectionEl.trigger('hide');
        sectionEl.addClass('hide');
        sectionEl.trigger('hidden');
      }

      showSection = showSection && section.skipped;
    }

    if (!topic.progressiveReveal || showSection) {
      $(topic.jqElement).removeClass('hideActions');
    } else {
      $(topic.jqElement).addClass('hideActions');
    }

    if (scrollLastSectionToView && lastVisibleSection) {
      scrollLastSectionPosition = lastVisibleSection.offset().top - 28;
      setTimeout(function () {
        $('html, body').animate({
          scrollTop: scrollLastSectionPosition
        }, 300);
      }, 60);
    }

    scrollLastSectionToView = false;
  }

  function updateTopicProgressBar(topicIndex) {
    var topic = topics[topicIndex];
    var percentToDo = topic.sections.length === 0 ? !topic.topicCompleted * 100 : (1 - topic.sectionsSkipped / topic.sections.length) * 100;
    $(topic.jqListElement).css('background-position-y', percentToDo + '%');
  }

  function i18nextLang(fallbackLng) {
    return i18next.language || window.localStorage.i18nextLng || fallbackLng || 'en';
  }

  function handleSkipClick(event) {
    $(this).data('n_clicks', $(this).data('n_clicks') + 1);
    var sectionId = this.getAttribute('data-section-id');
    var topicIndex = -1;
    var sectionIndex = -1;
    var topic, section;
    $.each(topics, function (ti, t) {
      $.each(t.sections, function (si, s) {
        if (sectionId === s.id) {
          topicIndex = ti;
          sectionIndex = si;
          topic = t;
          section = s;
          return false;
        }
      });
      return topicIndex === -1;
    });

    if (section.exercises.length && !section.completed && !section.allowSkip) {
      var exs = i18next.t(['text.exercise', 'exercise'], {
        count: section.exercises.length,
        lngs: [i18nextLang(), 'en']
      });
      var youmustcomplete = i18next.t(['text.youmustcomplete', 'You must complete the']);
      var inthissection = i18next.t(['text.inthissection', 'in this section before continuing.']);
      bootbox.setLocale(i18nextLang());
      bootbox.alert(youmustcomplete + ' ' + exs + ' ' + inthissection);
    } else {
      if (sectionIndex === topic.sections.length - 1) {
        if (topicIndex < topics.length - 1) {
          updateLocation(currentTopicIndex + 1);
        }
      } else {
        scrollLastSectionToView = true;
      }

      sectionSkipped([section.jqElement]);
      tutorial.skipSection(sectionId);
    }
  }

  function handleNextTopicClick(event) {
    if (topics[currentTopicIndex].sections.length === 0) {
      tutorial.skipSection(topics[currentTopicIndex].id);
    }

    updateLocation(currentTopicIndex + 1);
  }

  function handlePreviousTopicClick(event) {
    updateLocation(currentTopicIndex - 1);
  }

  function buildTopicsList() {
    var topicsList = $('<nav id="tutorial-topic" class="topicsList hideFloating" aria-label="topic"></nav>');
    var topicsHeader = $('<header class="topicsHeader"></header>');
    topicsHeader.append($('<h1 class="tutorialTitle">' + titleText + '</h1>'));
    var topicsCloser = $('<div class="paneCloser"></div>');
    topicsCloser.on('click', hideFloatingTopics);
    topicsHeader.append(topicsCloser);
    topicsList.append(topicsHeader);
    var topicsNav = isBS3() ? $('<ul class="nav nav-pills nav-stacked" role="menubar" aria-orientation="vertical" aria-label="topic"></ul>') : $('<ul class="nav flex-column" role="menubar" aria-orientation="vertical" aria-label="topic"></ul>');
    topicsList.append(topicsNav);
    $('#doc-metadata').appendTo(topicsList);
    resetSectionVisibilityList();
    var topicsDOM = $('.section.level2');
    topicsDOM.each(function (topicIndex, topicElement) {
      var topic = {};
      topic.id = $(topicElement).attr('id');
      topic.exercisesCompleted = 0;
      topic.sectionsCompleted = 0;
      topic.sectionsSkipped = 0;
      topic.topicCompleted = false;
      topic.jqElement = topicElement;
      topic.jqTitleElement = $(topicElement).children('h2')[0];
      topic.titleText = topic.jqTitleElement.innerText;
      var progressiveAttr = $(topicElement).attr('data-progressive');

      if (_typeof(progressiveAttr) !== (typeof undefined === "undefined" ? "undefined" : _typeof(undefined)) && progressiveAttr !== false) {
        topic.progressiveReveal = progressiveAttr === 'true' || progressiveAttr === 'TRUE';
      } else {
        topic.progressiveReveal = docProgressiveReveal;
      }

      var jqTopic = $("<li class=\"topic".concat(isBS3() ? '' : ' nav-item', "\" index=\"").concat(topicIndex, "\">") + "<a href=\"#".concat(topic.id, "\" class = \"nav-link\" role=\"menuitem\" tabindex=\"0\">").concat(topic.titleText, "</a>") + '</li>');
      jqTopic.on('click', handleTopicClick);
      topic.jqListElement = jqTopic;
      $(topicsNav).append(jqTopic);
      var topicActions = $('<div class="topicActions"></div>');

      if (topicIndex > 0) {
        var prevButton = $('<button class="btn btn-default" data-i18n="button.previoustopic">Previous Topic</button>');
        prevButton.on('click', handlePreviousTopicClick);
        topicActions.append(prevButton);
      }

      if (topicIndex < topicsDOM.length - 1) {
        var nextButton = $('<button class="btn btn-primary" data-i18n="button.nexttopic">Next Topic</button>');
        nextButton.on('click', handleNextTopicClick);
        topicActions.append(nextButton);
      }

      $(topicElement).append(topicActions);
      $(topicElement).on('shown', function () {
        if ($(this).is(':visible')) {
          var _sectionsDOM = $(topicElement).children('.section.level3');

          _sectionsDOM.each(function (sectionIndex, sectionElement) {
            updateSectionVisibility(sectionElement);
          });
        }
      });
      $(topicElement).on('hidden', function () {
        var sectionsDOM = $(topicElement).children('.section.level3');
        sectionsDOM.each(function (sectionIndex, sectionElement) {
          updateSectionVisibility(sectionElement);
        });
      });
      topic.sections = [];
      var sectionsDOM = $(topicElement).children('.section.level3');
      sectionsDOM.each(function (sectionIndex, sectionElement) {
        if (topic.progressiveReveal) {
          var continueButton = $('<button class="btn btn-default skip" id="' + 'continuebutton-' + sectionElement.id + '" data-section-id="' + sectionElement.id + '" data-i18n="button.continue">Continue</button>');
          continueButton.data('n_clicks', 0);
          continueButton.on('click', handleSkipClick);
          var actions = $('<div class="exerciseActions"></div>');
          actions.append(continueButton);
          $(sectionElement).append(actions);
        }

        $(sectionElement).on('shown', function () {
          updateSectionVisibility(sectionElement);
        });
        $(sectionElement).on('hidden', function () {
          updateSectionVisibility(sectionElement);
        });
        var section = {};
        section.exercises = [];
        var exercisesDOM = $(sectionElement).children('.tutorial-exercise');
        exercisesDOM.each(function (exerciseIndex, exerciseElement) {
          var exercise = {};
          exercise.dataLabel = $(exerciseElement).attr('data-label');
          exercise.completed = false;
          exercise.jqElement = exerciseElement;
          section.exercises.push(exercise);
        });
        var allowSkipAttr = $(sectionElement).attr('data-allow-skip');
        var sectionAllowSkip = docAllowSkip;

        if (_typeof(allowSkipAttr) !== (typeof undefined === "undefined" ? "undefined" : _typeof(undefined)) && allowSkipAttr !== false) {
          sectionAllowSkip = allowSkipAttr = 'true' || allowSkipAttr === 'TRUE';
        }

        section.id = sectionElement.id;
        section.completed = false;
        section.allowSkip = sectionAllowSkip;
        section.skipped = false;
        section.jqElement = sectionElement;
        topic.sections.push(section);
      });
      topics.push(topic);
    });
    var topicsFooter = $('<footer class="topicsFooter"></footer>');
    var resetButton = $('<span class="resetButton" data-i18n="text.startover">Start Over</span>');
    resetButton.on('click', function () {
      var areyousure = i18next.t(['text.areyousure', 'Are you sure you want to start over? (all exercise progress will be reset)']);
      bootbox.setLocale(i18nextLang());
      bootbox.confirm(areyousure, function (result) {
        result && tutorial.startOver();
      });
    });
    topicsFooter.append(resetButton);
    topicsList.append(topicsFooter);
    return topicsList;
  }

  var topicMenuInputBinding = new Shiny.InputBinding();
  $.extend(topicMenuInputBinding, {
    find: function find(scope) {
      return $(scope).find('.topicsList');
    },
    getValue: function getValue(el) {
      if (currentTopicIndex === -1) return null;
      return topics[currentTopicIndex].id;
    },
    setValue: function setValue(el, value) {
      for (var i = 0; i < topics.length; i++) {
        if (topics[i].id === value) {
          setCurrentTopic(i, false);
          break;
        }
      }
    },
    subscribe: function subscribe(el, callback) {
      setCurrentTopicNotifiers.add(el.id, callback);
    },
    unsubscribe: function unsubscribe(el) {
      setCurrentTopicNotifiers.remove(el.id);
    }
  });
  Shiny.inputBindings.register(topicMenuInputBinding, 'learnr.topicMenuInputBinding');
  var continueButtonInputBinding = new Shiny.InputBinding();
  $.extend(continueButtonInputBinding, {
    find: function find(scope) {
      return $(scope).find('.exerciseActions > button.skip');
    },
    getId: function getId(el) {
      return 'continuebutton-' + el.getAttribute('data-section-id');
    },
    getValue: function getValue(el) {
      return $(el).data('n_clicks');
    },
    setValue: function setValue(el, value) {
      var valueCurrent = $(el).data('n_clicks');

      if (value > valueCurrent) {
        $(el).trigger('click');
      }

      $(el).data('n_clicks', value);
    },
    subscribe: function subscribe(el, callBack) {
      $(el).on('click.continueButtonInputBinding', function (event) {
        callBack(false);
      });
    },
    unsubscribe: function unsubscribe(el) {
      $(el).off('.continueButtonInputBinding');
    }
  });
  Shiny.inputBindings.register(continueButtonInputBinding, 'learnr.continueButtonInputBinding');

  function transformDOM() {
    titleText = $('title')[0].innerText;
    var progAttr = $('meta[name=progressive]').attr('content');
    docProgressiveReveal = progAttr === 'true' || progAttr === 'TRUE';
    var allowSkipAttr = $('meta[name=allow-skip]').attr('content');
    docAllowSkip = allowSkipAttr === 'true' || allowSkipAttr === 'TRUE';
    var tutorialTitle = $("<h1 class=\"tutorialTitle\">".concat(titleText, "</h1>"));
    tutorialTitle.on('click', showFloatingTopics);
    $('.topics').prepend(tutorialTitle);
    $('.bandContent.topicsListContainer').append(buildTopicsList());

    for (var t = 0; t < topics.length; t++) {
      updateVisibilityOfTopicElements(t);
    }

    removeFootnoteLinks();

    function handleResize() {
      $('.topicsList').css('max-height', window.innerHeight);
    }

    handleResize();
    window.addEventListener('resize', handleResize);
  }

  function removeFootnoteLinks() {
    $('.footnote-ref').replaceWith(function () {
      var el = $('<span>');
      el.addClass($(this)["class"]);
      el.append($(this).html());
      return el;
    });
    $('.footnote-back').remove();
  }

  function isBS3() {
    return !window.bootstrap;
  }

  function preTransformDOMMigrateFromBS3() {
    var _this = this;

    if (isBS3()) return;
    document.querySelectorAll('.btn-xs').forEach(function (el) {
      _newArrowCheck(this, _this);

      el.classList.remove('btn-xs');
      el.classList.add('btn-sm');
    }.bind(this));
    document.querySelectorAll('.sr-only').forEach(function (el) {
      _newArrowCheck(this, _this);

      if (el.classList.contains('visually-hidden-focusable')) return;
      el.classList.add('visually-hidden');
    }.bind(this));
    var panelMigration = {
      panel: 'card',
      'panel-default': '',
      'panel-heading': 'card-header',
      'panel-title': 'card-title',
      'panel-body': 'card-body',
      'panel-footer': 'card-footer'
    };
    var tutorialMigratePanels = document.querySelectorAll('.tutorial-exercise-input, .tutorial-question-container');

    if (tutorialMigratePanels.length === 0) {
      return;
    }

    tutorialMigratePanels.forEach(function (elPanel) {
      var _this2 = this;

      _newArrowCheck(this, _this);

      Object.keys(panelMigration).forEach(function (classOrig) {
        var _this3 = this;

        _newArrowCheck(this, _this2);

        var els = [elPanel].concat(_toConsumableArray(elPanel.querySelectorAll(".".concat(classOrig))));
        if (!els.length) return;
        var classNew = panelMigration[classOrig];
        els.forEach(function (el) {
          _newArrowCheck(this, _this3);

          if (!el.classList.contains(classOrig)) return;
          el.classList.remove(classOrig);

          if (classNew !== '') {
            el.classList.add(classNew);
          }
        }.bind(this));
      }.bind(this));
    }.bind(this));
  }

  function handleLocationHash() {
    function findTopicIndexFromHash() {
      var hash = window.decodeURIComponent(window.location.hash);
      var topicIndex = 0;

      if (hash.length > 0) {
        $.each(topics, function (ti, t) {
          if ('#' + t.id === hash) {
            topicIndex = ti;
            return false;
          }
        });
      }

      return topicIndex;
    }

    setCurrentTopic(findTopicIndexFromHash());
    window.addEventListener('popstate', function (e) {
      setCurrentTopic(findTopicIndexFromHash());
    });
  }

  function sectionCompleted(section) {
    var jqSection = $(section);
    var topicCompleted = jqSection.hasClass('level2');
    var topicId;

    if (topicCompleted) {
      topicId = jqSection.attr('id');
    } else {
      topicId = $(jqSection.parents('.section.level2')).attr('id');
    }

    var topicIndex = -1;
    $.each(topics, function (ti, t) {
      if (t.id === topicId) {
        topicIndex = ti;
        return false;
      }
    });

    if (topicIndex === -1) {
      console.log('topic "' + topicId + '" not found');
      return;
    }

    var topic = topics[topicIndex];

    if (topicCompleted) {
      topic.topicCompleted = true;
      updateTopicProgressBar(topicIndex);
    } else {
      var sectionIndex = -1;
      var sectionId = jqSection.attr('id');
      $.each(topic.sections, function (si, s) {
        if (s.id === sectionId) {
          sectionIndex = si;
          return false;
        }
      });

      if (sectionIndex === -1) {
        console.log('completed section"' + sectionId + '"not found');
        return;
      }

      var _section = topic.sections[sectionIndex];

      if (!_section.completed) {
        topic.sectionsCompleted++;
        updateTopicProgressBar(topicIndex);
        $(_section.jqElement).addClass('done');
        _section.completed = true;
        updateVisibilityOfTopicElements(topicIndex);
      }
    }
  }

  var visibleSections = [];

  function resetSectionVisibilityList() {
    visibleSections.splice(0, visibleSections.length);
    sendVisibleSections();
  }

  function updateSectionVisibility(sectionElement) {
    var idx = visibleSections.indexOf(sectionElement.id);

    if ($(sectionElement).is(':visible')) {
      if (idx === -1) {
        visibleSections.push(sectionElement.id);
        sendVisibleSections();
      }
    } else {
      if (idx !== -1) {
        visibleSections.splice(idx, 1);
        sendVisibleSections();
      }
    }
  }

  function sendVisibleSections() {
    if (Shiny && Shiny.setInputValue) {
      Shiny.setInputValue('tutorial-visible-sections', visibleSections);
    } else {
      $(document).on('shiny:sessioninitialized', function () {
        Shiny.setInputValue('tutorial-visible-sections', visibleSections);
      });
    }
  }

  function sectionSkipped(exerciseElement) {
    var sectionSkippedId;

    if (exerciseElement.length) {
      sectionSkippedId = exerciseElement[0].id;
    } else {
      console.log('section ' + $(exerciseElement).selector.split('"')[1] + ' not found');
      return;
    }

    var topicIndex = -1;
    $.each(topics, function (ti, topic) {
      if (sectionSkippedId === topic.id) {
        topicIndex = ti;
        topic.topicCompleted = true;
        return false;
      }

      $.each(topic.sections, function (si, section) {
        if (sectionSkippedId === section.id) {
          topicIndex = ti;
          section.skipped = true;
          topic.sectionsSkipped++;
          return false;
        }
      });
      return topicIndex === -1;
    });
    updateTopicProgressBar(topicIndex);
    updateVisibilityOfTopicElements(topicIndex);
  }

  preTransformDOMMigrateFromBS3();
  transformDOM();
  handleLocationHash();
  tutorial.onInit(function () {
    tutorial.onProgress(function (progressEvent) {
      if (progressEvent.event === 'section_completed') {
        sectionCompleted(progressEvent.element);
      } else if (progressEvent.event === 'section_skipped') {
        sectionSkipped(progressEvent.element);
      }
    });
  });
});